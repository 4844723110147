<script setup lang="ts">
import { getTranslatedProperty } from '@shopware-pwa/helpers-next';
import type { UseBreakpointsReturn } from '@vueuse/core';
import type { Schemas } from '#shopware';

const props = defineProps<{
    isOpen: boolean;
    navItems: Schemas['Category'][];
    menuWrapperHeight?: number;
}>();

const { isOpen, navItems, menuWrapperHeight } = toRefs(props);

const breakpoints: UseBreakpointsReturn = inject('breakpoints') as UseBreakpointsReturn;
const isLG = breakpoints.greaterOrEqual('lg');
const { getPath } = useSeoPath();
const { isTouchDevice } = useDeviceDetection();
const { headerHeight } = useHeaderState();
const headerHeightPixel = computed(() => (headerHeight.value ? headerHeight.value + 'px' : '0'));

const flyoutTopOffset = computed(() => (isLG.value && menuWrapperHeight.value ? menuWrapperHeight.value + 'px' : 0));

const currentMenuId = ref<string | null>(null);
const lvl3Items = computed<Schemas['Category'][] | null>(() => {
    const children = navItems.value?.find((category) => category.id === currentMenuId.value)?.children || null;

    return children?.length ? children : null;
});

const { isActivePath } = useFlyoutMenu();

const setCurrentCategoryActive = () => {
    for (const navItem of navItems.value) {
        if (isActivePath(navItem)) {
            currentMenuId.value = navItem.id;
            break;
        }
    }
};

const HOVER_THRESHOLD = 150;
const hoverTimer = ref<NodeJS.Timeout | undefined>(undefined);
const onMouseEnter = (categoryId: string) => {
    // used timeout to prevent occasional hover on diagonal mouse path, user needs to hover an item for threshold duration to trigger menu change
    hoverTimer.value = setTimeout(() => {
        currentMenuId.value = categoryId;
    }, HOVER_THRESHOLD);
};
const onMouseLeave = () => {
    clearTimeout(hoverTimer.value);
};
const onTouchStart = (categoryId: string) => {
    // timeout for NuxtLink click to use old value
    setTimeout(() => {
        currentMenuId.value = categoryId;
    }, HOVER_THRESHOLD);
};

if (import.meta.client) {
    watch(isOpen, (open) => {
        if (!open) {
            currentMenuId.value = null;
        } else {
            setCurrentCategoryActive();
        }
    });
}
</script>

<template>
    <Transition
        v-if="isLG"
        enterActiveClass="transition origin-top"
        enterFromClass="scale-y-0"
        enterToClass="scale-y-100"
        leaveActiveClass="transition origin-top"
        leaveFromClass="scale-y-100"
        leaveToClass="scale-y-0"
    >
        <div
            v-if="isOpen"
            class="absolute"
            :style="{ top: flyoutTopOffset }"
        >
            <div
                class="flyout-content flex min-w-[300px] md:max-w-[984px] xl:max-w-[1240px] 2xl:max-w-[1496px] overflow-hidden rounded-b-md shadow-lg"
            >
                <div class="flex flex-col bg-gray-100 z-1 py-2.5 basis-[300px] flex-shrink-0 overflow-y-auto">
                    <LayoutCategory
                        v-for="navItem2 in navItems"
                        :key="navItem2.id"
                        :category="navItem2"
                        :data-id="navItem2.id"
                        class="flex gap-3 justify-between items-center rounded-lg pr-5 hover:text-brand-primary py-1.75 p-x-6.5 max-w-[300px]"
                        :class="{
                            'text-brand-primary font-bold': isActivePath(navItem2),
                            'text-brand-primary': currentMenuId === navItem2.id,
                        }"
                        :is-touch="isTouchDevice"
                        :is-active="currentMenuId === navItem2.id"
                        @mouseenter="onMouseEnter(navItem2.id)"
                        @mouseleave="onMouseLeave"
                        @touchstart.passive="onTouchStart(navItem2.id)"
                    >
                        {{ getTranslatedProperty(navItem2, 'name') }}
                        <SvgoAngleRight
                            v-if="navItem2.childCount > 0"
                            class="h-4.75 w-auto flex-shrink-0"
                        />
                    </LayoutCategory>
                </div>

                <Transition
                    enterActiveClass="transition origin-left"
                    enterFromClass="scale-x-0"
                    enterToClass="scale-x-100"
                    leaveActiveClass="transition origin-left"
                    leaveFromClass="scale-x-100"
                    leaveToClass="scale-x-0"
                >
                    <div
                        v-if="lvl3Items"
                        class="grow basis-4/5 bg-white py-5 px-7.5 overflow-y-auto"
                    >
                        <Transition
                            enterActiveClass="transition"
                            enterFromClass="opacity-0"
                            enterToClass="opacity-100"
                            leaveActiveClass="transition"
                            leaveFromClass="opacity-100"
                            leaveToClass="opacity-0"
                            mode="out-in"
                        >
                            <div
                                v-if="lvl3Items"
                                :key="lvl3Items.length"
                                class="gap-5 columns-4"
                            >
                                <template
                                    v-for="navItem3 in lvl3Items"
                                    :key="navItem3.id"
                                >
                                    <div
                                        v-if="navItem3.extensions?.attributes?.breakBefore"
                                        class="block break-after-column"
                                    />
                                    <div class="inline-block w-full pb-6.5 hyphens-auto break-words">
                                        <LayoutCategory
                                            :category="navItem3"
                                            class="flex justify-between rounded-lg font-bold hover:text-brand-primary"
                                            :data-id="navItem3.id"
                                            :class="{
                                                'pb-2': navItem3.childCount > 0,
                                                'text-brand-primary font-bold': isActivePath(navItem3),
                                            }"
                                        />
                                        <LayoutFlyoutLvlFourItems
                                            v-if="navItem3.childCount > 0"
                                            :show-max="navItem3.extensions?.attributes?.showMax || null"
                                            :nav-items="navItem3.children"
                                            :parent-link="getPath(navItem3)"
                                        />
                                    </div>
                                    <div
                                        v-if="navItem3.extensions?.attributes?.breakAfter"
                                        class="block break-after-column"
                                    />
                                </template>
                            </div>
                        </Transition>
                    </div>
                </Transition>
            </div>
        </div>
    </Transition>
</template>

<style lang="scss" scoped>
.flyout-content {
    max-height: calc(100dvh - v-bind(headerHeightPixel) - 1rem);
}
</style>
